import { Button, makeStyles, Spinner, tokens } from "@fluentui/react-components";
import { Home20Regular } from "@fluentui/react-icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserLicenses } from '../apis/GraphApi';

const useStyles = makeStyles({
  page: {
    backgroundColor: tokens.colorBrandBackground2,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: "100%"
  },
  homeButton: {
    position: 'absolute',
    right: "30px",
    "@media (min-width: 992px)": {
      right: "calc(4.17vw + 30px)"
    }
  },
  view: {
    width: "100%",
    height: "100vh",
    border: 0
  }
});

export default function LicenseComparisonPage() {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [licenses, setLicenses] = useState([] as string[]);

  useEffect(() => {
    getUserLicenses()
      .then(result => {
        setLicenses(result.data.value.map(x => x.skuId));
        setLoaded(true);
      })
      .catch((e) => {
        if (e.code === "ConsentFailed" || e.code === "InternalError")
          navigate("../tab");
        else
          alert(e.message);
      });
  }, [navigate]);

  const styles = useStyles();

  return (
    <div className={styles.page}>
      <Button
        className={styles.homeButton}
        icon={<Home20Regular/>}
        onClick={() => navigate('/tab')}
      />
      {loaded ? (
        <iframe 
          className={styles.view}
          title="License Comparison View"
          sandbox="allow-forms allow-modals allow-popups allow-popups-to-escape-sandbox allow-pointer-lock allow-scripts allow-same-origin allow-downloads allow-top-navigation-to-custom-protocols"
          src={`https://o365hq.com/srv_identifier_iframe?${licenses.map(id => `guid%5b%5d=${id}`).join('&')}`}>
        </iframe> 
      ) : (
        <Spinner
          size="large"
          style={{
            flexGrow: 1
          }} 
        />
      )}
    </div>
  );
}
