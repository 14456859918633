import { TenantNotRegisteredAlertProps } from "./TenantNotRegisteredAlertProps";
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Text } from "@fluentui/react-components";
import { Warning24Regular } from "@fluentui/react-icons";
import { useGetUserInfo } from "../../../apis/TenantOptimizerApi";
import { useQueryClient } from "react-query";

export const TenantNotRegisteredAlert = (props: TenantNotRegisteredAlertProps) => {
  const { isOpen, clientId, redirectUri, close, onGrantSuccess } = props;
  const { data: user, isSuccess } = useGetUserInfo();
  const queryClient = useQueryClient();

  const onGrantingClick = async () => {
    const adminConsentUrl = `https://login.microsoftonline.com/${user.tenantId}/adminconsent?client_id=${clientId}&redirect_uri=${redirectUri}&state=teamsMode`;
    const size = 600;
    const top = (window.screen.height - size) / 2;
    const left = (window.screen.width - size) / 2;
    const newWindow = window.open(adminConsentUrl, "auth", `width=${size},height=${size},top=${top},left=${left}`);
    const timer = setInterval(function () {
      try {
        if (newWindow.location?.origin === window.location.origin) {
          clearInterval(timer);
          newWindow.close();
          queryClient.invalidateQueries("hasLicenseOptimizerPermissions");
          onGrantSuccess();
          clearInterval(timer);
          close();
        }
      } catch (e) {
        console.debug("Cannot read popup window location due to cross-origin policy");
      }
    }, 1000);
  }
  
  if (!isSuccess) {
    return <div>Error! TenantId is not defined</div>;
  }
  
  return (
    <Dialog open={isOpen}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>
            Tenant not registered
          </DialogTitle>
          <DialogContent>
            <Warning24Regular />
            <span> </span>
            <Text>
              We need access to your tenant in order to initiate the analysis. Please, grant us permission to read the data.
              Ensuring the security and protection of customer data is our highest value. Therefore, IT Partner uses its own analytical engine, and all calculations are performed in our environment.
            </Text>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={close}
            >
              Close
            </Button>
            <Button 
              onClick={onGrantingClick}
              appearance="primary"
            >
              Grant permissions
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
};