import {
  FluentProvider,
  Spinner,
  teamsDarkTheme,
  teamsHighContrastTheme,
  teamsLightTheme,
  tokens,
} from "@fluentui/react-components";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, HashRouter as Router, Routes } from "react-router-dom";
import "./AxiosConfig";
import { TeamsFxContext } from "./Context";
import config from "./config";
import Hub from "./pages/Hub";
import { ConsiderOfferPage, LicenseRequestPage, PrepareOfferPage } from "./pages/LicenseAssistant";
import { HistoryFeedPage } from "./pages/LicenseAssistant/HistoryFeed";
import LicenseComparisonPage from "./pages/LicenseComparison";
import MigrationReportPage from "./pages/MigrationReportPage";
import TenantOptimizerPage from "./pages/TenantOptimizerPage";
import { reactPlugin } from "./utils/ApplicationInsightsConfig";

const queryClient = new QueryClient();

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId!,
  });
  const customTheme = themeString === "dark"
    ? teamsDarkTheme
    : themeString === "contrast"
    ? teamsHighContrastTheme
    : teamsLightTheme
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
        <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
          <QueryClientProvider client={queryClient}>
          <FluentProvider
            theme={
              {
                ...customTheme,
                colorNeutralBackground3: "#BDBDE6",
                colorStatusDangerBackground1:"#F3D6D8",
                colorStatusWarningBackground1: "#F1D765",
                colorBrandBackground: '#0099DA',
                colorBrandBackgroundHover: '#0077A0',
                colorBrandBackgroundPressed: '#006688'
              }
            }
            style={{ background: tokens.colorBrandBackground2 }}
          >
          <AppInsightsErrorBoundary onError={() => <h1>Something went wrong</h1>} appInsights={reactPlugin}>
            <Router>
              {loading ? (
                <Spinner
                  size="large"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "100%"
                  }} 
                />
              ) : (
                <Routes>
                  <Route path="/tab" element={<Hub />} />
                  <Route path="/license-comparison" element={<LicenseComparisonPage />} />
                  <Route path="/license-order" element={<LicenseRequestPage />} />
                  <Route path="/license-order/:id/prepare" element={<PrepareOfferPage />} />
                  <Route path="/license-order/:id/consider" element={<ConsiderOfferPage />} />
                  <Route path="/history-feed" element={<HistoryFeedPage />} />
                  <Route path="/tenant-optimizer" element={<TenantOptimizerPage />} />
                  <Route path="/migration-report" element={<MigrationReportPage />} />
                </Routes>
              )}
            </Router>
      </AppInsightsErrorBoundary>
          </FluentProvider>
          </QueryClientProvider>
        </TeamsFxContext.Provider>
    </AppInsightsContext.Provider>
  );
}
