import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from "react-query";
import config from "../config";
import axios, { HttpStatusCode } from "axios";
import { Auth } from "../utils/auth";
import { UserInfo } from "@microsoft/teamsfx";

export const useGetUserInfo = (): UseQueryResult<UserInfo, unknown> => {
    const query = useQuery("userInfo", async () => {
        return await Auth.getUserInfo();
    }, { staleTime: 100_000 });
    return query;
}

export interface CreateAnalyticsRequestParams {
	TenantId: string;
    InitiatorName: string;
	InitiatorEmail: string;
	ReceiveMonthlyReports: boolean;
}

export const useCreateAnalyticsRequestMutation = (onSuccess?: (data: void, variables: CreateAnalyticsRequestParams, context: unknown) => Promise<unknown> | void) : UseMutationResult<void, unknown, CreateAnalyticsRequestParams, unknown> => {
	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationFn: async (params: CreateAnalyticsRequestParams) => {
			const apiUrl = `${config.tenantOptimizerBackendUrl}/createAnalyticsRequest`;
			await axios.post(apiUrl, params);
			queryClient.invalidateQueries({ queryKey: "lastAnalyticsRequest" });
		},
		onSuccess: onSuccess
	});
	return mutation;
};

export interface UpdateSchedulerParams {
	TenantId: string;
	Scheduler?: SchedulerSettings;
}

export interface DateLabel {
	label: string;
	time: number;
};

export interface SchedulerSettings {
	Initiator: string;
	SendToGlobalAdmins: boolean;
	AdditionalRecipients: string[];
}

export interface TenantItem {
	companyName: string;
	tenantId: string;
	enrollmentTime: DateLabel;
	lastAnalysisDate: DateLabel | null;
	lastAnalysisStatus: string | null;
	scheduler: SchedulerSettings;
	reportId: string | null;
};

export interface CheckPermissionsResult {
	TenantId: string;
	HasPermissions: boolean;
}

export const useUpdateSchedulerMutation = (onSuccess?: (data: void, variables: UpdateSchedulerParams, context: unknown) => Promise<unknown> | void) : UseMutationResult<void, unknown, UpdateSchedulerParams, unknown> => {
	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationFn: async (params: UpdateSchedulerParams) => {
            var token = await Auth.getToken([]);
			const bearer = `Bearer ${token.token}`;
			const apiUrl = `${config.tenantOptimizerBackendUrl}/updateScheduler`;
			await axios.post(apiUrl, params, {
				headers: {
					Authorization: bearer
				}
			});
			queryClient.invalidateQueries({ queryKey: "tenantsList" });
		},
		onSuccess: onSuccess
	});
	return mutation;
}


export type CustomerAnalyticsRequestDto = {
	RequestDate: string;
	InitiatorName: string;
	InitiatorEmail: string;
	ReceiveMonthlyReports: boolean;
}

export const useGetLastCustomerAnalytics = (tenantId: string, enabled: boolean) : UseQueryResult<CustomerAnalyticsRequestDto | null, Error> => {
	return useQuery<unknown, Error, CustomerAnalyticsRequestDto>('lastAnalyticsRequest', async () => {
		const apiUrl = `${config.tenantOptimizerBackendUrl}/getLastCustomerAnalytics`;
		const response = await axios.request({
			url: apiUrl,
			method: 'get',
			params: {
				TenantId: tenantId
			}
		});
		if (response.status === HttpStatusCode.NoContent) {
			return null;
		}
		return response.data;
	}, { staleTime: 10_000, enabled });

};

export interface TenantRegistrationCommand {
	TenantId: string;
}

export interface TenantRegistrationResult {
	TenantId: string;
}

export const useRegisterTenant = (onSuccess?: (data: TenantRegistrationResult, variables: TenantRegistrationCommand, context: unknown) => Promise<unknown> | void) : UseMutationResult<TenantRegistrationResult, unknown, TenantRegistrationCommand, unknown> => {
	const queryClient = useQueryClient();
	const mutation = useMutation({
		mutationFn: async (params: TenantRegistrationCommand) => {
			const token = await Auth.getToken([]);
			const bearer = `Bearer ${token.token}`;
			const apiUrl = `${config.tenantOptimizerBackendUrl}/registerTenant`;
			const result = await axios.post(apiUrl, params, {
				headers: {
					Authorization: bearer
				}
			});
			queryClient.invalidateQueries({ queryKey: "tenantsList" });
			return result.data;
		},
		onSuccess: onSuccess
	});
	return mutation;
}

export const useHasLicenseOptimizerPermissions = (): UseQueryResult<CheckPermissionsResult, unknown> => {
	const credential = Auth.getCredential();
	const query = useQuery({
		queryKey: ["hasLicenseOptimizerPermissions"],
		queryFn: async () => {
			const userInfo = await credential.getUserInfo();
			const apiUrl = `${config.tenantOptimizerBackendUrl}/checkPermissions?tenantId=${userInfo.tenantId}`;
			const result = await axios.get<CheckPermissionsResult>(apiUrl);
			return result.data;
		},
		onError: async (error) => {
			console.error(error);
		},
		staleTime: 60_000 
	});
	return query;
};