import { Dialog, DialogSurface, DialogBody, DialogTitle, DialogContent, Text, makeStyles, DialogActions, Button} from "@fluentui/react-components";
import { AccessDeniedAlertProps } from "./AccessDeniedAlertProps";
import { PresenceBlocked24Regular } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  card: {
    width: "360px"
  },
  title: {
    textAlign: 'center'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // add space
    gap: '8px'
  },
  button: {
    boxShadow: "0px 4px 4px #00000025",
  }
});

export const AccessDeniedAlert = (props: AccessDeniedAlertProps) => {
  const { open, reason } = props;
  const styles = useStyles();
  const navigate = useNavigate();
  return (
    <Dialog modalType="non-modal" open={open}>
      <DialogSurface className={styles.card}>
        <DialogBody>
          <DialogTitle className={styles.title} action={null}>
            Access Denied
          </DialogTitle>
          <DialogContent className={styles.content}>
            <PresenceBlocked24Regular color="red"/>
            <Text >
              {reason}
            </Text>
          </DialogContent>
          <DialogActions>
            <Button
              className={styles.button} 
              onClick={() => navigate("/tab")}
            >
              Close
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
